import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import userContext from '../context/UserContext';
import { PDFDownloadLink } from '@react-pdf/renderer';
import AttendanceReportPdf from './ReportPdf'; // Import the PDF component
import { FaFilePdf } from 'react-icons/fa';

export default function StaffAttReport() {
  const { token } = useContext(userContext);
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [attendanceRecords, setAttendanceRecords] = useState([]);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 10; // Items to display per page

  useEffect(() => {
    const fetchData = async () => {
      try {
        const yearsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/staffatt/years`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setYears(yearsResponse.data);

        const monthsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/staffatt/months`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setMonths(monthsResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [token]);

  const handleFetchAttendance = async () => {
    if (!selectedYear || !selectedMonth) {
      alert("Please select both year and month before fetching attendance.");
      return;
    }
  
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/staffattsheet`, {
        headers: { Authorization: `Bearer ${token}` },
        params: { attyear: selectedYear, attmonth: selectedMonth }
      });
  
      if (Array.isArray(response.data.attendanceRecords)) {
        setAttendanceRecords(response.data.attendanceRecords);
      } else {
        console.error("Unexpected response format:", response.data);
        alert("No attendance records available.");
      }
      setCurrentPage(1);
    } catch (error) {
      console.error("Error fetching attendance records:", error);
      alert("An error occurred while fetching attendance records.");
    }
  };
  

  const daysInMonth = selectedYear && selectedMonth ? new Date(selectedYear, selectedMonth, 0).getDate() : 0;

  // Calculate total pages for pagination
  const totalPages = Math.ceil(attendanceRecords.length / recordsPerPage);

  // Calculate records to show on current page
  const startIndex = (currentPage - 1) * recordsPerPage;
  const currentAttendanceRecords = attendanceRecords.slice(startIndex, startIndex + recordsPerPage);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div style={{ background: "white" }}>
      <h3 className='text-center'><strong><u>STAFF ATTENDANCE REPORT</u></strong></h3>
      <hr />
      <div className="row mb-3 align-items-center">
        <div className="col-md-2">
          <select
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            className="form-control mx-2 shadow p-2 rounded-pill border-3 border-info"
          >
            <option value="" disabled>Select Year</option>
            {years.map((year) => (
              <option key={year.year} value={year.year}>
                {year.year}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-2">
          <select
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            className="form-control mx-2 shadow p-2 rounded-pill border-3 border-info"
          >
            <option value="" disabled>Select Month</option>
            {months.map((month) => (
              <option key={month.month} value={month.month}>
                {month.month}
              </option>
            ))}
          </select>
        </div>
        <div className='col-md-4'>

        <button onClick={handleFetchAttendance} className="btn btn-primary btn-sm mb-1 shadow p-3">
          <strong>Fetch Attendance</strong>
        </button>
        </div>
        <div className='col-md-2'>

        <PDFDownloadLink 
                        document={<AttendanceReportPdf attendanceRecords={attendanceRecords} selectedMonth={selectedMonth} selectedYear={selectedYear} daysInMonth={daysInMonth} />} 
                        fileName={`attendance_report_${selectedMonth}_${selectedYear}.pdf`}
                    >
                        <button className="btn btn-success">
                            <FaFilePdf /> 
                        </button>
                    </PDFDownloadLink>
        </div>

      </div>

      <hr className='border-2' />
      {currentAttendanceRecords.length > 0 ? (
        <div>
          <h4 className='text-center text-success'> RAHBAR PUBLIC SCHOOL - STAFF ATTENDANCE ({selectedMonth} of {selectedYear})</h4>
          <table className="table table-bordered mt-3 border-2">
            <thead>
              <tr>
                <th>Ser</th>
                <th>Name</th>
                {Array.from({ length: daysInMonth }, (_, index) => (
                  <th key={index + 1}>{index + 1}</th>
                ))}
              </tr>
            </thead>
            <tbody>
  {currentAttendanceRecords.map((record, index) => (
    <tr key={record.att_adm_no}>
      <td>{startIndex + index + 1}</td>
      <td>{record.name}</td>
      {Array.from({ length: daysInMonth }, (_, dayIndex) => {
        const attendance = Array.isArray(record.attendance) ? record.attendance[dayIndex] : null;

        // Render attendance status or leave blank
        return (
          <td key={dayIndex}>
            {attendance === 'P' ? 'P' :
             attendance === 'A' ? 'A' : 
             ''}
          </td>
        );
      })}
    </tr>
  ))}
</tbody>


          </table>

          {/* Pagination Controls */}
          <div className="pagination">
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                className={`btn ${currentPage === index + 1 ? "btn-success" : "btn-primary"}`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      ) : (
        <div className="text-center text-warning">
          <h5>No attendance records available for the selected criteria. Please try a different month or year.</h5>
        </div>
      )}
    </div>
  );
}
